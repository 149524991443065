import React, {ReactElement, useEffect, useState} from "react";
import {ServiceType} from "../../type/ServiceType";
import {MenuPart} from "./MenuPart";
import {
    Button, ButtonGroup,
    CircularProgress,
    Grid2,
    Table,
    TableBody,
    TableCell, TableFooter,
    TableHead,
    TableRow,
    Toolbar,
    Typography
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {ServiceModal} from "./modal/ServiceModal";
import {Popup} from "../../Popup";
import {REST} from "../../REST";
import dayjs from "dayjs";

export function ServiceAdminPage(props: any): ReactElement {
    const [loading, setLoading] = useState<boolean>(true);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [services, setServices] = useState<ServiceType[]>();
    const [popup, setPopup] = useState<{
        type?: "error" | "success" | "info" | "warning" | undefined,
        title: string,
        message: string
    }>();

    useEffect(() => {
        REST.getService().then((ss) => {
            setServices(ss);
            setLoading(false);
        }).catch((reason) => {
            setPopup({title: 'Ошибка получения данных', message: reason.stack});
        })
    }, [])

    const removeRow = (id: bigint) => {
        REST.removeService(id).then(() => {
            setServices((prev: ServiceType[] | undefined) => prev!.filter(item => item.id !== id));
        });
    }

    return (<>
        <MenuPart selected={'menuService'} title={'Услуги'} addButton={
            <Button sx={{flexGrow: 0, ml: 2}} startIcon={<AddIcon/>} variant="contained" color={'success'}
                    disabled={loading}
                    onClick={() => setShowModal(true)}>Добавить</Button>}/>
        <Grid2
            container
            spacing={0}
            direction="column"
            justifyContent="top"
            sx={{minHeight: '100vh', minWidth: '100vw'}}
        >
            <Toolbar/>
            <Grid2 container spacing={2} direction="row" alignItems="center" justifyContent="center"
                   sx={{mt: 2, mb: 2}}>
                <Grid2 size={{xs: 12, sm: 8}}></Grid2>
            </Grid2>

            <Table size="small">
                <TableHead>
                    <TableRow key={'header'}>
                        <TableCell align="center"><Typography variant={'button'} sx={{fontWeight: 'bold'}}
                                                              align={'center'}>Название</Typography></TableCell>
                        <TableCell align="center"><Typography variant={'button'} sx={{fontWeight: 'bold'}}
                                                              align={'center'}>Продолжительность</Typography></TableCell>
                        <TableCell align="center"><Typography variant={'button'} sx={{fontWeight: 'bold'}}
                                                              align={'center'}>Стоимость</Typography></TableCell>
                        <TableCell align="center"><Typography variant={'button'} sx={{fontWeight: 'bold'}}
                                                              align={'center'}>Действия</Typography></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading ?
                        <TableRow>
                            <TableCell align="center" colSpan={4}
                                       sx={{display: {xs: 'none', md: 'table-cell'}}}><CircularProgress
                                color="inherit"/></TableCell>
                            <TableCell align="center" colSpan={3}
                                       sx={{display: {xs: 'table-cell', md: 'none'}}}><CircularProgress
                                color="inherit"/></TableCell>
                        </TableRow>
                        : services?.map((row) => (
                            <TableRow key={row.id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <TableCell component="th" scope="row"
                                           align="center">{row.name}</TableCell>
                                <TableCell align="center">
                                    <Grid2 container direction={'row'} spacing={2} justifyContent="center"
                                           alignItems={"center"} sx={{display: {xs: 'none', md: 'flex'}}}>
                                        {row.pre || row.post
                                            ? <>
                                                <Typography
                                                    variant={'caption'}>{dayjs.unix(row.pre ?? 0).format('HH:mm')}</Typography>
                                                <Typography
                                                    variant={'body1'}>{dayjs.unix(row.duration).format('HH:mm')}</Typography>
                                                <Typography
                                                    variant={'caption'}>{dayjs.unix(row.post ?? 0).format('HH:mm')}</Typography>
                                            </>
                                            : <Typography
                                                variant={'body1'}>{dayjs.unix(row.duration).format('HH:mm')}</Typography>
                                        }
                                    </Grid2>
                                    <Grid2 container direction={'column'} spacing={0} justifyContent="center"
                                           alignItems={"center"} sx={{display: {xs: 'flex', md: 'none'}}}>
                                        {row.pre || row.post
                                            ? <>
                                                <Typography
                                                    variant={'caption'}>{dayjs.unix(row.pre ?? 0).format('HH:mm')}</Typography>
                                                <Typography
                                                    variant={'body1'}>{dayjs.unix(row.duration).format('HH:mm')}</Typography>
                                                <Typography
                                                    variant={'caption'}>{dayjs.unix(row.post ?? 0).format('HH:mm')}</Typography>
                                            </>
                                            : <Typography
                                                variant={'body1'}>{dayjs.unix(row.duration).format('HH:mm')}</Typography>
                                        }
                                    </Grid2>
                                </TableCell>
                                <TableCell component="th" scope="row"
                                           align="center">{row.price} руб.</TableCell>
                                <TableCell>
                                    <ButtonGroup orientation={'horizontal'}
                                                 sx={{display: {xs: 'none', md: 'flex'}, justifyContent: 'center'}}>
                                        <Button variant={'contained'} color={'primary'}
                                                disabled={true}><EditIcon/></Button>
                                        <Button variant={'contained'} color={'error'}
                                                onClick={() => removeRow(row.id)}><DeleteIcon/></Button>
                                    </ButtonGroup>
                                    <ButtonGroup orientation={'vertical'} size={'small'}
                                                 sx={{display: {xs: 'flex', md: 'none'}, justifyContent: 'center'}}>
                                        <Button variant={'contained'} color={'primary'}
                                                disabled={true}><EditIcon/></Button>
                                        <Button variant={'contained'} color={'error'}
                                                onClick={() => removeRow(row.id)}><DeleteIcon/></Button>
                                    </ButtonGroup>
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
                <TableFooter>

                </TableFooter>
            </Table>

        </Grid2>
        <ServiceModal show={[showModal, setShowModal]} setServices={setServices}/>
        {popup ? <Popup title={popup?.title} message={popup?.message} open={true}/> : ''}
    </>)
}