import {ReactElement, useState} from "react";
import {REST} from "../../../REST";
import {
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    InputLabel,
    OutlinedInput
} from "@mui/material";
import {OfficeType} from "../../../type/OfficeType";

export function OfficeModal(props: any): ReactElement {
    const [open, setOpen] = props.show;
    const [name, setName] = useState<string>('');
    const [address, setAddress] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [canCreate, setCanCreate] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const handleClose = () => {
        setName('');
        setAddress('');
        setDescription('');
        setError(false);
        setOpen(false);
    };

    const createObject = () => {
        REST.createOffice(name!, address!, description!).then(oo => {
            props.setOffices((prev: OfficeType[]) => [...prev, oo]);
        }).catch(reason => {
            //setError(true);
        }).finally(() => {
            handleClose();
        });
    }

    if (!open) {
        return (<></>);
    }

    return (<Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Филиал</DialogTitle>
        <DialogContent dividers>
            <DialogContentText>

            </DialogContentText>
            <FormControl fullWidth error={error} sx={{mb: 2}} required>
                <InputLabel htmlFor={'officeName'}>Название филиала</InputLabel>
                <OutlinedInput id={'officeName'} label={"Название филиала"} onChange={(e) => {
                    setName(e.target.value);
                    setCanCreate(e.target.value.length > 0 && address.length > 0);
                }}/>
            </FormControl>

            <FormControl fullWidth error={error} sx={{mb: 2}} required>
                <InputLabel htmlFor={'officeAddress'}>Адрес филиала</InputLabel>
                <OutlinedInput id={'officeAddress'} label={"Адрес филиала"} onChange={(e) => {
                    setAddress(e.target.value);
                    setCanCreate(e.target.value.length > 0 && name.length > 0);
                }}/>
            </FormControl>

            <FormControl fullWidth error={error}>
                <InputLabel htmlFor={'officeDescription'}>Описание филиала</InputLabel>
                <OutlinedInput multiline rows={4} id={'officeDescription'} label={"Описание филиала"} onChange={(e) => {
                    setDescription(e.target.value);
                }}/>
            </FormControl>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color={'error'}>Отмена</Button>
            <Button autoFocus onClick={createObject} variant={'contained'} disabled={!canCreate}>Создать</Button>
        </DialogActions>
    </Dialog>);
}