import React, {ReactElement} from "react";
import {AppBar, Avatar, Badge, Box, IconButton, Menu, MenuItem, Toolbar, Typography} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import GroupsIcon from "@mui/icons-material/Groups";
import BusinessIcon from "@mui/icons-material/Business";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import BadgeIcon from "@mui/icons-material/Badge";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import PersonIcon from "@mui/icons-material/Person";
import ApartmentIcon from "@mui/icons-material/Apartment";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import {useNavigate} from "react-router-dom";
import {REST} from "../../REST";

export function MenuPart(props: any): ReactElement {
    const navigate = useNavigate();
    const selected: string = props.selected;
    const title: string = props.title;
    const addButton: React.ReactNode | null = props.addButton as React.ReactNode | null;

    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const [anchorElMenu, setAnchorElMenu] = React.useState<null | HTMLElement>(null);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElMenu(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorElMenu(null);
    };

    return (
        <AppBar>
            <Toolbar>
                <Box sx={{flexGrow: 0}}>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{mr: 2}}
                        onClick={handleOpenMenu}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Menu
                        sx={{mt: '45px'}}
                        id="menu-appbar"
                        anchorEl={anchorElMenu}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElMenu)}
                        onClose={handleCloseMenu}
                    >
                        <MenuItem key={"menuClient"} onClick={handleCloseMenu} disabled>
                            <GroupsIcon/><Typography sx={{textAlign: 'center', ml: 1}}>Клиенты</Typography>
                        </MenuItem>
                        <MenuItem key={"menuOffice"} onClick={() => navigate('/company/office')}
                                  selected={selected === 'menuOffice'}>
                            <BusinessIcon/><Typography sx={{textAlign: 'center', ml: 1}}>Филиалы</Typography>
                        </MenuItem>
                        <MenuItem key={"menuService"} onClick={() => navigate('/company/service')}
                                  selected={selected === 'menuService'}>
                            <ReceiptLongIcon/><Typography sx={{textAlign: 'center', ml: 1}}>Услуги</Typography>
                        </MenuItem>
                        <MenuItem key={"menuEmployee"} onClick={() => navigate('/company/employee')}
                                  selected={selected === 'menuEmployee'}>
                            <BadgeIcon/><Typography sx={{textAlign: 'center', ml: 1}}>Сотрудники</Typography>
                        </MenuItem>
                        <MenuItem key={"menuPost"} onClick={() => navigate('/company/post')}
                                  selected={selected === 'menuPost'}>
                            <BusinessCenterIcon/><Typography
                            sx={{textAlign: 'center', ml: 1}}>Должности</Typography>
                        </MenuItem>
                    </Menu>
                </Box>

                {title
                    ? <Box sx={{flexGrow: 0, display: {xs: 'none', sm: 'block'}}}>
                        <Typography variant={'button'}>{title}</Typography>
                    </Box>
                    : ''
                }
                {addButton
                    ? addButton
                    : ''
                }
                <Box sx={{flexGrow: 1}}/>

                <Box sx={{flexGrow: 0, mr: 1}}>
                    <IconButton size="large" color="inherit">
                        <Badge badgeContent={Math.floor(Math.random() * (99 - 1) + 1)} color="error">
                            <NotificationsIcon/>
                        </Badge>
                    </IconButton>
                </Box>
                <Box sx={{flexGrow: 0}}>
                    <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                        <Avatar alt="Remy Sharp"
                                src="https://mui.com/static/images/avatar/2.jpg"><PersonOutlineIcon/></Avatar>
                    </IconButton>
                    <Menu
                        sx={{mt: '45px'}}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                    >
                        <MenuItem key={"userProfile"} onClick={handleCloseUserMenu} disabled>
                            <PersonIcon/><Typography sx={{textAlign: 'center', ml: 1}}>Профиль</Typography>
                        </MenuItem>
                        <MenuItem key={"userCompany"} onClick={handleCloseUserMenu} disabled>
                            <ApartmentIcon/><Typography sx={{textAlign: 'center', ml: 1}}>Организация</Typography>
                        </MenuItem>
                        <MenuItem key={"userSettings"} onClick={handleCloseUserMenu} divider disabled>
                            <SettingsIcon/><Typography sx={{textAlign: 'center', ml: 1}}>Настройки</Typography>
                        </MenuItem>
                        <MenuItem key={"userLogout"} onClick={() => REST.logout()}>
                            <LogoutIcon/><Typography sx={{textAlign: 'center', ml: 1}}>Выйти</Typography>
                        </MenuItem>

                    </Menu>
                </Box>

            </Toolbar>
        </AppBar>
    );
}