import React, {ReactElement, useEffect, useState} from "react";
import {
    Autocomplete, Avatar, Button,
    ButtonGroup, CircularProgress,
    Grid2, IconButton,
    InputAdornment, Table, TableBody, TableCell, TableFooter, TableHead, TableRow,
    TextField, Toolbar, Typography
} from "@mui/material";
import {MenuPart} from "./MenuPart";
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {Popup} from "../../Popup";
import {EmployeeType} from "../../type/EmployeeType";
import {REST} from "../../REST";
import {EmployeeModal} from "./modal/EmployeeModal";

export function EmployeeAdminPage(props: any): ReactElement {
    //const paginationModel = { page: 0, pageSize: 5 };
    const [loading, setLoading] = useState<boolean>(true);
    const [popup, setPopup] = useState<{
        type?: "error" | "success" | "info" | "warning" | undefined,
        title: string,
        message: string
    }>();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [employees, setEmployees] = useState<EmployeeType[]>();

    useEffect(() => {
        REST.getEmployee().then((ee) => {
            setEmployees(ee);
            setLoading(false);
        }).catch((reason) => {
            setPopup({title: 'Ошибка получения данных', message: reason.stack});
        })
    }, [])

    const removeRow = (id: bigint) => {
        REST.removeEmployee(id).then(() => {
            setEmployees((prev: EmployeeType[] | undefined) => prev!.filter(item => item.id !== id));
        });
    }

    return (<>
        <MenuPart selected={'menuEmployee'} title={'Сотрудники'}
                  addButton={<Button sx={{flexGrow: 0, ml: 2}} startIcon={<AddIcon/>} variant="contained"
                                     color={'success'}
                                     disabled={loading} onClick={() => setShowModal(true)}>Добавить</Button>}/>
        <Grid2
            container
            spacing={0}
            direction="column"
            justifyContent="top"
            sx={{minHeight: '100vh', minWidth: '100vw'}}
        >
            <Toolbar/>
            <Grid2 container spacing={2} direction="row" alignItems="center" justifyContent="center"
                   sx={{mt: 2, mb: 2}}>
                <Grid2 size={{xs: 12, sm: 8}}>
                    <Autocomplete
                        sx={{flexGrow: 1, display: {xs: 'none', sm: 'flex'}}}
                        id="search"
                        size="small"
                        freeSolo
                        options={[]}
                        renderInput={(params) =>
                            <TextField {...params} label="Поиск сотрудников по имени или должности"
                                       slotProps={{
                                           input: {
                                               endAdornment: <InputAdornment
                                                   position="end"><IconButton><SearchIcon/></IconButton></InputAdornment>,
                                           },
                                       }}
                            />}
                    />
                </Grid2>
            </Grid2>

            <Table size="small">
                <TableHead>
                    <TableRow key={'header'}>
                        <TableCell align="center"><Typography variant={'button'} sx={{fontWeight: 'bold'}}
                                                              align={'center'}>Сотрудник</Typography></TableCell>
                        <TableCell align="center"><Typography variant={'button'} sx={{fontWeight: 'bold'}}
                                                              align={'center'}>Филиал</Typography></TableCell>
                        <TableCell align="center"><Typography variant={'button'} sx={{fontWeight: 'bold'}}
                                                              align={'center'}>Действия</Typography></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading ?
                        <TableRow>
                            <TableCell align="center" colSpan={3}><CircularProgress color="inherit"/></TableCell>
                        </TableRow>
                        : employees?.map((row) => (
                            <TableRow key={row.id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <TableCell component="th" scope="row" align="center">
                                    <Grid2 container spacing={1} direction="row" wrap={'nowrap'} justifyContent="left">
                                        <Avatar alt={row.fio}
                                                src={process.env.REACT_APP_BASE + '/api/employee/' + row.id + '/avatar'}><PersonOutlineIcon/></Avatar>
                                        <Grid2 container direction={'column'} spacing={0}>
                                            <Typography variant={'subtitle2'}
                                                        sx={{fontWeight: 'bold'}}>{row.fio}</Typography>
                                            {row.post
                                                ? <Typography variant={'caption'}
                                                              align={'left'}>{row.post.name}</Typography>
                                                : <Typography variant={'caption'} color={'error'}
                                                              sx={{fontStyle: 'italic'}}>Не
                                                    указано</Typography>
                                            }
                                        </Grid2>
                                    </Grid2>
                                </TableCell>
                                <TableCell align="center">
                                    <Grid2 container direction={'column'} spacing={0}>
                                        {row.office
                                            ? <><Typography variant={'body2'}>{row.office.name}</Typography>
                                                <Typography variant={'caption'}>{row.office.address}</Typography>
                                            </>
                                            :
                                            <Typography variant={'caption'} color={'error'} sx={{fontStyle: 'italic'}}>Не
                                                указан</Typography>
                                        }
                                    </Grid2>
                                </TableCell>
                                <TableCell>
                                    <ButtonGroup orientation={'horizontal'}
                                                 sx={{display: {xs: 'none', md: 'flex'}, justifyContent: 'center'}}>
                                        <Button variant={'contained'} color={'primary'}
                                                disabled={true}><EditIcon/></Button>
                                        <Button variant={'contained'} color={'error'}
                                                onClick={() => removeRow(row.id)}><DeleteIcon/></Button>
                                    </ButtonGroup>
                                    <ButtonGroup orientation={'vertical'} size={'small'}
                                                 sx={{display: {xs: 'flex', md: 'none'}, justifyContent: 'center'}}>
                                        <Button variant={'contained'} color={'primary'}
                                                disabled={true}><EditIcon/></Button>
                                        <Button variant={'contained'} color={'error'}
                                                onClick={() => removeRow(row.id)}><DeleteIcon/></Button>
                                    </ButtonGroup>
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
                <TableFooter>

                </TableFooter>
            </Table>

        </Grid2>
        <EmployeeModal show={[showModal, setShowModal]} setEmployees={setEmployees}/>
        {popup ? <Popup title={popup?.title} message={popup?.message} open={true}/> : ''}
    </>)
}
