import {ReactElement, useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl, InputLabel, OutlinedInput
} from "@mui/material";
import {REST} from "../../../REST";
import {PostType} from "../../../type/PostType";

export function PostModal(props: any): ReactElement {
    const [open, setOpen] = props.show;
    const [name, setName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [canCreate, setCanCreate] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const handleClose = () => {
        setName('');
        setDescription('');
        setError(false);
        setOpen(false);
    };

    const createObject = () => {
        REST.createPost(name!, description!).then(pp => {
            props.setPosts((prev: PostType[]) => [...prev, pp]);
        }).catch(reason => {
            //setError(true);
        }).finally(() => {
            handleClose();
        });
    }

    if (!open) {
        return (<></>);
    }

    return (<Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Должность</DialogTitle>
        <DialogContent dividers>
            <DialogContentText>

            </DialogContentText>
            <FormControl fullWidth error={error} sx={{mb: 2}} required>
                <InputLabel htmlFor={'postName'}>Название должности</InputLabel>
                <OutlinedInput id={'postName'} label={"Название должности"} onChange={(e) => {
                    setName(e.target.value);
                    setCanCreate(e.target.value.length > 0);
                }}/>
            </FormControl>

            <FormControl fullWidth error={error}>
                <InputLabel htmlFor={'postDescription'}>Описание должности</InputLabel>
                <OutlinedInput multiline rows={4} id={'postDescription'} label={"Описание должности"} onChange={(e) => {
                    setDescription(e.target.value);
                }}/>
            </FormControl>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color={'error'}>Отмена</Button>
            <Button autoFocus onClick={createObject} variant={'contained'} disabled={!canCreate}>Создать</Button>
        </DialogActions>
    </Dialog>);
}