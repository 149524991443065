import React, {ReactElement, useState} from "react";
import {REST} from "../../../REST";
import {ServiceType} from "../../../type/ServiceType";
import {
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl, InputAdornment,
    InputLabel,
    OutlinedInput
} from "@mui/material";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {TimePicker} from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";

export function ServiceModal(props: any): ReactElement {
    const [open, setOpen] = props.show;
    const [name, setName] = useState<string>('');
    const [price, setPrice] = useState<number>(0.0);
    const [duration, setDuration] = useState<number>(0);
    const [pre, setPre] = useState<number>(0);
    const [post, setPost] = useState<number>(0);
    const [canCreate, setCanCreate] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const handleClose = () => {
        setName('');
        setPrice(0.0);
        setDuration(0);
        setPre(0);
        setPost(0);
        setError(false);
        setOpen(false);
    };

    const createObject = () => {
        let s: ServiceType = {
            id: BigInt(0),
            name: name,
            duration: duration,
            price: price,
            pre: pre,
            post: post
        }
        REST.createService(s).then(ss => {
            props.setServices((prev: ServiceType[]) => [...prev, ss]);
        }).catch(reason => {
            //setError(true);
        }).finally(() => {
            handleClose();
        });
    }

    if (!open) {
        return (<></>);
    }

    return (<Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Услуга</DialogTitle>
        <DialogContent dividers>
            <DialogContentText>

            </DialogContentText>
            <FormControl fullWidth error={error} sx={{mb: 2}} required>
                <InputLabel htmlFor={'serviceName'}>Название услуги</InputLabel>
                <OutlinedInput id={'serviceName'} label={"Название услуги"} onChange={(e) => {
                    setName(e.target.value);
                    setCanCreate(e.target.value.length > 0 && price > 0.0 && duration > 0);
                }}/>
            </FormControl>
            <FormControl fullWidth error={error} sx={{mb: 2}}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker format="HH:mm" ampm={false} label={"Время на подготовку"} timeSteps={{minutes: 1}}
                                onChange={(value) => {
                        setPre(value!.unix());
                    }}/>
                </LocalizationProvider>
            </FormControl>
            <FormControl fullWidth error={error} sx={{mb: 2}} required>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker format="HH:mm" ampm={false}
                                label={"Продолжительность *"}
                                timeSteps={{minutes: 1}}
                                onChange={(value) => {
                                    setDuration(value!.unix());
                                    setCanCreate(value!.unix() > 0 && name.length > 0 && price > 0.0);
                                }}/>
                </LocalizationProvider>
            </FormControl>
            <FormControl fullWidth error={error} sx={{mb: 2}}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker format="HH:mm" ampm={false} label={"Время на завершение"} timeSteps={{minutes: 1}}
                                onChange={(value) => {
                        setPost(value!.unix());
                    }}/>
                </LocalizationProvider>
            </FormControl>
            <FormControl fullWidth error={error} required>
                <InputLabel htmlFor={'servicePrice'}>Стоимость</InputLabel>
                <OutlinedInput id={'servicePrice'} label={"Стоимость"}
                               endAdornment={<InputAdornment position="start">руб.</InputAdornment>} onChange={(e) => {
                    setPrice(parseFloat(e.target.value));
                    setCanCreate(parseFloat(e.target.value) > 0.0 && name.length > 0 && duration > 0);
                }}/>
            </FormControl>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color={'error'}>Отмена</Button>
            <Button autoFocus onClick={createObject} variant={'contained'} disabled={!canCreate}>Создать</Button>
        </DialogActions>
    </Dialog>);
}