import React, {ReactElement} from "react";
import {AppBar, Box, Button, Container, Divider, Grid2, IconButton, Link, Toolbar, Typography} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';

export default function IndexPage(props: any): ReactElement {
    return (<>
        <Grid2
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{minHeight: '100vh'}}
        >
            <AppBar>
                <Toolbar>
                    <Box>
                        <Link href={"/"} color={'inherit'} underline="none" sx={{display: {xs: 'none', md: 'flex'}}}>
                            <Typography noWrap sx={{fontFamily: "Playwrite CU"}}
                                        variant={"h6"}>{process.env.REACT_APP_TITLE}</Typography>
                        </Link>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{mr: 2, display: {md: 'none'}}}
                        >
                            <MenuIcon/>
                        </IconButton>
                    </Box>

                    <Box sx={{flexGrow: 1}}></Box>
                    <Box>
                        <Button color={"inherit"} href={"/login"}>Войти</Button>
                    </Box>
                    <Box sx={{ml: 1, display: {xs: 'none', sm: 'block'}}}>
                        <Button color={"warning"} variant={'contained'} href={"/register"}>Зарегистрироваться</Button>
                    </Box>
                </Toolbar>
            </AppBar>

            <Container fixed>

                <Typography component={"h2"} variant={"h2"}
                            sx={{
                                "text-align": "center",
                                fontFamily: "Playwrite CU"
                            }}>{process.env.REACT_APP_TITLE}</Typography>

                <Typography component={"h4"} variant={"h4"} sx={{"text-align": "center"}} color={'primary'}>Онлайн-запись
                    и автоматизация</Typography>

            </Container>

            <AppBar sx={{top: 'auto', bottom: 0, display: {xs: 'none', sm: 'block'}}}>
                <Toolbar>
                    <Container fixed>
                        <Grid2 container direction="row" alignItems="top" justifyContent="center" spacing={10}>
                            <Box sx={{mt: 1, mb: 2}}>
                                <Typography variant="button">Контакты</Typography>
                                <Divider sx={{mb: 2, mt: 1}}/>
                                <Link underline="none" sx={{display: 'block'}} color={'inherit'} variant={'caption'}
                                      href={'mailto:support@planoo.ru'}>support@planoo.ru</Link>
                            </Box>
                            <Box sx={{mt: 1, mb: 2, direction: 'row'}}>
                                <Typography variant="button">Компания</Typography>
                                <Divider sx={{mb: 2, mt: 1}}/>
                                <Link underline="none" sx={{display: 'block'}} color={'inherit'} variant={'caption'}
                                      href={'#'}>О
                                    нас</Link>
                                <Link underline="none" sx={{display: 'block'}} color={'inherit'} variant={'caption'}
                                      href={'#'}>Партнерам</Link>
                                <Link underline="none" sx={{display: 'block'}} color={'inherit'} variant={'caption'}
                                      href={'#'}>Разработчикам</Link>
                                <Link underline="none" sx={{display: 'block'}} color={'inherit'} variant={'caption'}
                                      href={'#'}>Контакты
                                    и реквизиты</Link>
                                <Link underline="none" sx={{display: 'block'}} color={'inherit'} variant={'caption'}
                                      href={'#'}>Цены</Link>
                                <Link underline="none" sx={{display: 'block'}} color={'inherit'} variant={'caption'}
                                      href={'#'}>Отзывы</Link>
                            </Box>
                            <Box sx={{mt: 1, mb: 2}}>
                                <Typography variant="button">Возможности</Typography>
                                <Divider sx={{mb: 2, mt: 1}}/>
                                <Link underline="none" sx={{display: 'block'}} color={'inherit'} variant={'caption'}
                                      href={'#'}>Онлайн-запись</Link>
                                <Link underline="none" sx={{display: 'block'}} color={'inherit'} variant={'caption'}
                                      href={'#'}>Уведомления</Link>
                                <Link underline="none" sx={{display: 'block'}} color={'inherit'} variant={'caption'}
                                      href={'#'}>Аналитика
                                    и отчеты</Link>
                            </Box>
                            <Box sx={{mt: 1, mb: 2}}>
                                <Typography variant="button">Правовая информация</Typography>
                                <Divider sx={{mb: 2, mt: 1}}/>
                                <Link underline="none" sx={{display: 'block'}} color={'inherit'} variant={'caption'}
                                      href={'#'}>Пользовательское
                                    соглашение</Link>
                                <Link underline="none" sx={{display: 'block'}} color={'inherit'} variant={'caption'}
                                      href={'#'}>Лицензионный
                                    договор</Link>
                                <Link underline="none" sx={{display: 'block'}} color={'inherit'} variant={'caption'}
                                      href={'#'}>Конфиденциальность</Link>
                            </Box>
                        </Grid2>
                    </Container>
                </Toolbar>
            </AppBar>
        </Grid2>
    </>)
}