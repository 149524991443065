import {jwtDecode} from 'jwt-decode';
//import {saveAs} from "file-saver";
import {JWT} from "./type/JWT";
import {PostType} from "./type/PostType";
import {OfficeType} from "./type/OfficeType";
import {EmployeeType} from "./type/EmployeeType";
import {ServiceType} from "./type/ServiceType";

export class REST {
    public static BASE: String = process.env.REACT_APP_BASE ?? "";

    public static login(username: string, password: string): Promise<JWT> {
        return fetch(this.BASE + "/api/auth", {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({username: username, password: password})
        })
            .then((response) => {
                if (response.status === 401) {
                    return response.json()
                        .then((e) => {
                            throw new Error(e['message'])
                        })
                        .catch((e: Error) => {
                            throw new Error(e.message)
                        });
                }
                return response.json();
            })
            .then((token) => {
                const jwt = jwtDecode<JWT>(token?.access_token);
                sessionStorage.setItem("me", JSON.stringify(jwt.user));
                sessionStorage.setItem("jwt", token?.access_token);
                //localStorage.setItem('token', token);
                return jwt;
            });
    }

    public static logout(): void {
        fetch(this.BASE + "/api/logout", {
            method: "GET",
            headers: {'Authorization': 'Bearer ' + sessionStorage.getItem("jwt")}
        })
            .catch((error) => {
                console.error(error);
                return [];
            })
            .finally(() => {
                sessionStorage.removeItem("me");
                sessionStorage.removeItem("jwt");
                window.location.href = '/login';
            });
    }

    public static sendCode(phone: string): void {
        fetch(this.BASE + "/api/register/code?phone=" + phone, {method: "GET"});
    }

    public static GET<T>(uri: string): Promise<T> {
        return fetch(this.BASE + uri, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem("jwt"),
            }
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/login';
                }
                return response.json();
            })
            .then((data: Promise<T>) => {
                return data;
            });
    }

    public static POST<T>(uri: string, body: T): Promise<T> {
        return fetch(this.BASE + uri, {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem("jwt"),
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body, (_, v) => typeof v === 'bigint' ? v.toString() : v)
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
                return response.json();
            })
            .then((data: Promise<T>) => {
                return data;
            });
    }

    public static DELETE(uri: string): Promise<any> {
        return fetch(this.BASE + uri, {
            method: "DELETE",
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem("jwt"),
            }
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/login';
                }
                return response;
            });
    }

    public static register(phone: string, code: string): Promise<any> {
        return fetch(this.BASE + "/api/register", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({phone: phone, code: code})
        })
            .then((response) => {
                return response.json();
            })
            .then((data: Promise<any>) => {
                return data;
            });
    }

    public static getPost(): Promise<PostType[]> {
        return REST.GET<PostType[]>("/api/post");
    }

    public static getOffice(): Promise<OfficeType[]> {
        return REST.GET<OfficeType[]>("/api/office");
    }

    public static getEmployee(): Promise<EmployeeType[]> {
        return REST.GET<EmployeeType[]>("/api/employee");
    }

    public static createPost(name: string, description: string): Promise<PostType> {
        return fetch(this.BASE + "/api/post", {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem("jwt"),
                "Content-Type": "application/json"
            },
            body: JSON.stringify({name: name, description: description})
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
                return response.json();
            })
            .then((data: Promise<PostType>) => {
                return data;
            });
    }

    public static removePost(id: bigint): Promise<any> {
        return REST.DELETE("/api/post?id=" + id);
    }

    public static createOffice(name: string, address: string, description: string): Promise<OfficeType> {
        return fetch(this.BASE + "/api/office", {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem("jwt"),
                "Content-Type": "application/json"
            },
            body: JSON.stringify({name: name, address: address, description: description})
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
                return response.json();
            })
            .then((data: Promise<OfficeType>) => {
                return data;
            });
    }

    public static removeOffice(id: bigint): Promise<any> {
        return REST.DELETE("/api/office?id=" + id);
    }

    public static createEmployee(employee: EmployeeType): Promise<EmployeeType> {
        return REST.POST<EmployeeType>("/api/employee", employee);
    }

    public static removeEmployee(id: bigint): Promise<any> {
        return REST.DELETE("/api/employee?id=" + id);
    }

    public static getService(): Promise<ServiceType[]> {
        return REST.GET<ServiceType[]>("/api/service");
    }

    public static createService(service: ServiceType): Promise<ServiceType> {
        return REST.POST<ServiceType>("/api/service", service);
    }

    public static removeService(id: bigint): Promise<any> {
        return REST.DELETE("/api/service?id=" + id);
    }

    /*
public static download(f: FileType): void {
        fetch(this.BASE + "/api/file/download?fid=" + f.id, {
            method: "GET",
            headers: {'Authorization': 'Bearer ' + sessionStorage.getItem("jwt")}
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
                return response.blob();
            })
            .then((blob) => {
                saveAs(blob, f.name);
            });
    }


     */
}