import React, {ReactElement, useEffect, useState} from "react";
import {
    Autocomplete, Button,
    Grid2, IconButton,
    InputAdornment,
    TableHead,
    Table,
    TextField, Toolbar, Typography, TableCell, TableBody, TableRow, TableFooter, ButtonGroup, CircularProgress
} from "@mui/material";
import {MenuPart} from "./MenuPart";
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {PostType} from "../../type/PostType";
import {REST} from "../../REST";
import {Popup} from "../../Popup";
import {PostModal} from "./modal/PostModal";

export function PostAdminPage(props: any): ReactElement {
    //const paginationModel = { page: 0, pageSize: 5 };
    const [loading, setLoading] = useState<boolean>(true);
    const [popup, setPopup] = useState<{
        type?: "error" | "success" | "info" | "warning" | undefined,
        title: string,
        message: string
    }>();
    const [posts, setPosts] = useState<PostType[]>();
    const [showModal, setShowModal] = useState<boolean>(false);

    useEffect(() => {
        REST.getPost().then((p) => {
            setPosts(p);
            setLoading(false);
        }).catch((reason) => {
            setPopup({title: 'Ошибка получения данных', message: reason.stack});
        })
    }, [])

    const removeRow = (id: bigint) => {
        REST.removePost(id).then(() => {
            setPosts((prev: PostType[] | undefined) => prev!.filter(item => item.id !== id));
        });
    }

    return (<>
        <MenuPart selected={'menuPost'} title={'Должности'}
                  addButton={<Button sx={{flexGrow: 0, ml: 2}} startIcon={<AddIcon/>} variant="contained"
                                     color={'success'}
                                     disabled={loading}
                                     onClick={() => setShowModal(true)}>Добавить</Button>}/>
        <Grid2
            container
            spacing={0}
            direction="column"
            justifyContent="top"
            sx={{minHeight: '100vh', minWidth: '100vw'}}
        >
            <Toolbar/>
            <Grid2 container spacing={2} direction="row" alignItems="center" justifyContent="center"
                   sx={{mt: 2, mb: 2}}>
                <Grid2 size={{xs: 12, sm: 8}}>
                    <Autocomplete
                        sx={{flexGrow: 1, display: {xs: 'none', sm: 'flex'}}}
                        size="small"
                        id="search"
                        freeSolo
                        options={[]}
                        renderInput={(params) =>
                            <TextField {...params} label="Поиск должности по названию"
                                       slotProps={{
                                           input: {
                                               endAdornment: <InputAdornment
                                                   position="end"><IconButton><SearchIcon/></IconButton></InputAdornment>,
                                           },
                                       }}
                            />}
                    />
                </Grid2>
            </Grid2>

            <Table size="small">
                <TableHead>
                    <TableRow key={'header'}>
                        <TableCell align="center"><Typography variant={'button'} sx={{fontWeight: 'bold'}}
                                                              align={'center'}>Название</Typography></TableCell>
                        <TableCell align="center"><Typography variant={'button'} sx={{fontWeight: 'bold'}}
                                                              align={'center'}>Описание</Typography></TableCell>
                        <TableCell align="center"><Typography variant={'button'} sx={{fontWeight: 'bold'}}
                                                              align={'center'}>Действия</Typography></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading ?
                        <TableRow>
                            <TableCell align="center" colSpan={3}><CircularProgress color="inherit"/></TableCell>
                        </TableRow>
                        : posts?.map((row) => (
                            <TableRow key={row.id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <TableCell component="th" scope="row" align="center">{row.name}</TableCell>
                                <TableCell align="center">{row.description}</TableCell>
                                <TableCell>
                                    <ButtonGroup orientation={'horizontal'}
                                                 sx={{display: {xs: 'none', md: 'flex'}, justifyContent: 'center'}}>
                                        <Button variant={'contained'} color={'primary'}
                                                disabled={true}><EditIcon/></Button>
                                        <Button variant={'contained'} color={'error'} onClick={() => removeRow(row.id)}><DeleteIcon/></Button>
                                    </ButtonGroup>
                                    <ButtonGroup orientation={'vertical'} size={'small'}
                                                 sx={{display: {xs: 'flex', md: 'none'}, justifyContent: 'center'}}>
                                        <Button variant={'contained'} color={'primary'}
                                                disabled={true}><EditIcon/></Button>
                                        <Button variant={'contained'} color={'error'} onClick={() => removeRow(row.id)}><DeleteIcon/></Button>
                                    </ButtonGroup>
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
                <TableFooter>

                </TableFooter>
            </Table>

        </Grid2>
        <PostModal show={[showModal, setShowModal]} setPosts={setPosts}/>
        {popup ? <Popup title={popup?.title} message={popup?.message} open={true}/> : ''}
    </>)
}
