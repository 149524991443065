import React, {ReactElement, useEffect, useRef, useState} from "react";
import {REST} from "../../../REST";
import {
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Grid2, InputAdornment,
    InputLabel, MenuItem,
    OutlinedInput,
    Select, Typography
} from "@mui/material";
import {EmployeeType} from "../../../type/EmployeeType";
import {PostType} from "../../../type/PostType";
import {OfficeType} from "../../../type/OfficeType";
import {IMaskInput} from "react-imask";
import {Normalizer} from "../../../utils/Normalizer";

export function EmployeeModal(props: any): ReactElement {
    const [open, setOpen] = props.show;
    const [fio, setFio] = useState<string>('');
    const [post, setPost] = useState<bigint>(BigInt(0));
    const [office, setOffice] = useState<bigint>(BigInt(0));
    const [employeePhone, setPhone] = useState<string | null>(null);
    const [canCreate, setCanCreate] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const [posts, setPosts] = useState<PostType[]>();
    const [offices, setOffices] = useState<OfficeType[]>();

    const phone = useRef<HTMLInputElement>();

    const handleClose = () => {
        setFio('');
        setPost(BigInt(0));
        setOffice(BigInt(0));
        setPhone('');
        setError(false);
        setOpen(false);
    };

    const createObject = () => {
        let e: EmployeeType = {
            id: BigInt(0),
            fio: fio!,
            post: {id: post!, name: '', description: ''},
            office: office > 0 ? {id: office, name: '', address: '', description: ''} : null,
            phone: employeePhone !== null ? BigInt(Normalizer.phone(employeePhone)) : null
        }
        REST.createEmployee(e).then(ee => {
            props.setEmployees((prev: EmployeeType[]) => [...prev, ee]);
        }).catch(reason => {
            //setError(true);
        }).finally(() => {
            handleClose();
        });
    }

    useEffect(() => {
        REST.getPost().then((p) => {
            setPosts(p);
        })
        REST.getOffice().then((o) => {
            setOffices(o);
        })
    }, [])

    if (!open) {
        return (<></>);
    }

    return (<Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Сотрудник</DialogTitle>
        <DialogContent dividers>
            <DialogContentText>

            </DialogContentText>
            <FormControl required fullWidth error={error} sx={{mb: 2}}>
                <InputLabel htmlFor={'employeeFio'}>ФИО</InputLabel>
                <OutlinedInput id={'employeeFio'} label={"ФИО"} onChange={(e) => {
                    setFio(e.target.value);
                    setCanCreate(e.target.value.length > 0 && post > BigInt(0));
                }}/>
            </FormControl>

            <FormControl required fullWidth error={error} sx={{mb: 2}}>
                <InputLabel htmlFor={'employeePost'}>Должность</InputLabel>
                <Select
                    id="employeePost"
                    label={"Должность"}
                    onChange={(e) => {
                        setPost(BigInt(e.target.value as string));
                        setCanCreate(BigInt(e.target.value as string) > 0 && fio.length > 0);
                    }}
                >
                    {posts?.map((p: PostType) => (<MenuItem value={p.id.toString()}>{p.name}</MenuItem>))}
                </Select>
            </FormControl>

            <FormControl fullWidth error={error} sx={{mb: 2}}>
                <InputLabel htmlFor={'employeeOffice'}>Филиал</InputLabel>
                <Select
                    id="employeeOffice"
                    label={"Филиал"}
                    onChange={(e) => {
                        setOffice(BigInt(e.target.value as string));
                    }}
                >
                    {offices?.map((o: OfficeType) => (
                        <MenuItem value={o.id.toString()}><Grid2 container direction={'column'} spacing={0}>
                            <Typography variant={'body1'}>{o.name}</Typography>
                            <Typography variant={'caption'}>{o.address}</Typography>
                        </Grid2></MenuItem>))}
                </Select>
            </FormControl>

            <FormControl fullWidth error={error}>
                <InputLabel htmlFor={"employeePhone"}>Телефон</InputLabel>
                <OutlinedInput id={"employeePhone"} name={"employeePhone"} label={"Телефон"} fullWidth
                               startAdornment={<InputAdornment position="start">+7</InputAdornment>}
                               inputComponent={IMaskInput}
                               onChange={(e) => setPhone(e.target.value)}
                               ref={phone}
                               inputProps={{mask: '(#00) 000-0000', definitions: {'#': /[1-9]/}}}
                />
            </FormControl>

        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color={'error'}>Отмена</Button>
            <Button autoFocus onClick={createObject} variant={'contained'} disabled={!canCreate}>Создать</Button>
        </DialogActions>
    </Dialog>);
}