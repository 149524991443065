import React, {ChangeEvent, ReactElement, useRef, useState} from "react";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent, CardHeader,
    Divider,
    FormControl,
    Grid2, InputAdornment, InputLabel,
    OutlinedInput, Stack, TextField, Typography
} from "@mui/material";
import {IMaskInput} from "react-imask";
import {Normalizer} from "../utils/Normalizer";
import {REST} from "../REST";

export default function LoginPage(props: any): ReactElement {
    const phone = useRef<HTMLInputElement>();
    const [loginDisabled, setLoginDisabled] = useState<boolean>(true);

    const [currentPhone, setCurrentPhone] = useState<string | null>(null);
    const [currentPassword, setCurrentPassword] = useState<string | null>(null);

    const handleOnChangePhone = (event: ChangeEvent<HTMLInputElement>) => {
        if (/^[1-9]{1}[0-9]{9}$/.test(Normalizer.phone(event.target.value))) {
            setCurrentPhone(Normalizer.phone(event.target.value));
        }
        if (/^[1-9]{1}[0-9]{9}$/.test(Normalizer.phone(event.target.value))
            && (currentPassword?.length ?? 0) > 0) {
            setLoginDisabled(false);
        } else {
            setLoginDisabled(true);
        }
    }

    const handleOnChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
        setCurrentPassword(event.target.value);
        if (event.target.value.length > 0
            && /^[1-9]{1}[0-9]{9}$/.test(Normalizer.phone(currentPhone ?? ''))) {
            setLoginDisabled(false);
        } else {
            setLoginDisabled(true);
        }
    }

    return (<>
        <Grid2
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{minHeight: '100vh', bgcolor: 'primary.main'}}
        >
            <Grid2 size={{sm: 2, md: 2, lg: 2, xl: 2, xs: 11}}>

                <Card variant={"outlined"}>
                    <CardHeader title={<Stack direction={"row"} spacing={1} sx={{justifyContent: 'center'}}>
                        <img src={"/favicon.png"} height={"28px"} alt={process.env.REACT_APP_TITLE}/>
                        <Typography component={"h5"} variant={"h5"}
                                    sx={{
                                        textAlign: "center",
                                        fontFamily: "Playwrite CU"
                                    }}>{process.env.REACT_APP_TITLE}</Typography></Stack>
                    }
                    />
                    <CardContent>

                        <FormControl variant="outlined" fullWidth component={"div"} required>
                            <InputLabel htmlFor={"phone"}>Телефон</InputLabel>
                            <OutlinedInput id={"phone"} name={"phone"} label={"Телефон"} fullWidth
                                           startAdornment={<InputAdornment position="start">+7</InputAdornment>}
                                           inputComponent={IMaskInput}
                                           onChange={handleOnChangePhone}
                                           ref={phone}
                                           inputProps={{mask: '(#00) 000-0000', definitions: {'#': /[1-9]/}}}
                            />
                        </FormControl>

                        <FormControl sx={{mt: 2}} variant="outlined" fullWidth={true} component={"div"} required={true}>
                            <TextField
                                id="password"
                                label={"Пароль *"}
                                type="password"
                                autoComplete="current-password"
                                onChange={handleOnChangePassword}
                                fullWidth
                                slotProps={{inputLabel: {shrink: true}}}
                            />
                        </FormControl>

                    </CardContent>
                    <Divider/>
                    <CardActions>
                        <Button color={"primary"} variant={"contained"} sx={{ml: 1, flexGrow: 0}}
                                disabled={loginDisabled}
                                onClick={() => REST.login(currentPhone!, currentPassword!)}>Войти</Button>
                        <Box sx={{flexGrow: 1}}></Box>
                        <Button color={"warning"} variant={"text"} sx={{mr: 1, flexGrow: 0}}
                                href={"/register"}>Зарегистрироваться</Button>
                    </CardActions>
                </Card>

            </Grid2>
        </Grid2>
    </>)
}