import React, {ReactElement, useEffect, useState} from "react";
import {MenuPart} from "./MenuPart";
import {
    Autocomplete,
    Button,
    ButtonGroup, CircularProgress,
    Grid2,
    IconButton,
    InputAdornment,
    Table, TableBody, TableCell, TableFooter, TableHead, TableRow,
    TextField,
    Toolbar,
    Typography
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import {Popup} from "../../Popup";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {REST} from "../../REST";
import {OfficeType} from "../../type/OfficeType";
import {OfficeModal} from "./modal/OfficeModal";

export function OfficeAdminPage(props: any): ReactElement {
    //const paginationModel = { page: 0, pageSize: 5 };
    const [loading, setLoading] = useState<boolean>(true);
    const [offices, setOffices] = useState<OfficeType[]>();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [popup, setPopup] = useState<{
        type?: "error" | "success" | "info" | "warning" | undefined,
        title: string,
        message: string
    }>();

    useEffect(() => {
        REST.getOffice().then((oo) => {
            setOffices(oo);
            setLoading(false);
        }).catch((reason) => {
            setPopup({title: 'Ошибка получения данных', message: reason.stack});
        })
    }, [])

    const removeRow = (id: bigint) => {
        REST.removeOffice(id).then(() => {
            setOffices((prev: OfficeType[] | undefined) => prev!.filter(item => item.id !== id));
        });
    }

    return (<>
        <MenuPart selected={'menuOffice'} title={'Филиалы'}
                  addButton={<Button sx={{flexGrow: 0, ml: 2}} startIcon={<AddIcon/>} variant="contained" color={'success'}
                                     disabled={loading}
                                     onClick={() => setShowModal(true)}>Добавить</Button>}/>
        <Grid2
            container
            spacing={0}
            direction="column"
            justifyContent="top"
            sx={{minHeight: '100vh', minWidth: '100vw'}}
        >
            <Toolbar/>
            <Grid2 container spacing={2} direction="row" alignItems="center" justifyContent="center" sx={{mt: 2, mb: 2}}>
                <Grid2 size={{xs: 12, sm: 8}}>
                    <Autocomplete
                        size="small"
                        sx={{flexGrow: 1, display: {xs: 'none', sm: 'flex'}}}
                        id="search"
                        freeSolo
                        options={[]}
                        renderInput={(params) =>
                            <TextField {...params} label="Поиск филиала по названию или адресу"
                                       slotProps={{
                                           input: {
                                               endAdornment: <InputAdornment
                                                   position="end"><IconButton><SearchIcon/></IconButton></InputAdornment>,
                                           },
                                       }}
                            />}
                    />
                </Grid2>

            </Grid2>

            <Table size="small">
                <TableHead>
                    <TableRow key={'header'}>
                        <TableCell align="center"><Typography variant={'button'} sx={{fontWeight: 'bold'}}
                                                              align={'center'}>Название</Typography></TableCell>
                        <TableCell align="center"><Typography variant={'button'} sx={{fontWeight: 'bold'}}
                                                              align={'center'}>Адрес</Typography></TableCell>
                        <TableCell align="center" sx={{display: {xs: 'none', md: 'table-cell'}}}><Typography
                            variant={'button'} sx={{fontWeight: 'bold'}}
                            align={'center'}>Описание</Typography></TableCell>
                        <TableCell align="center"><Typography variant={'button'} sx={{fontWeight: 'bold'}}
                                                              align={'center'}>Действия</Typography></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading ?
                        <TableRow>
                            <TableCell align="center" colSpan={4}
                                       sx={{display: {xs: 'none', md: 'table-cell'}}}><CircularProgress
                                color="inherit"/></TableCell>
                            <TableCell align="center" colSpan={3}
                                       sx={{display: {xs: 'table-cell', md: 'none'}}}><CircularProgress
                                color="inherit"/></TableCell>
                        </TableRow>
                        : offices?.map((row) => (
                            <TableRow key={row.id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <TableCell component="th" scope="row" align="center">{row.name}</TableCell>
                                <TableCell align="center">{row.address}</TableCell>
                                <TableCell align="center"
                                           sx={{display: {xs: 'none', md: 'table-cell'}}}>{row.description}</TableCell>
                                <TableCell>
                                    <ButtonGroup orientation={'horizontal'}
                                                 sx={{display: {xs: 'none', md: 'flex'}, justifyContent: 'center'}}>
                                        <Button variant={'contained'} color={'primary'}
                                                disabled={true}><EditIcon/></Button>
                                        <Button variant={'contained'} color={'error'}
                                                onClick={() => removeRow(row.id)}><DeleteIcon/></Button>
                                    </ButtonGroup>
                                    <ButtonGroup orientation={'vertical'} size={'small'}
                                                 sx={{display: {xs: 'flex', md: 'none'}, justifyContent: 'center'}}>
                                        <Button variant={'contained'} color={'primary'}
                                                disabled={true}><EditIcon/></Button>
                                        <Button variant={'contained'} color={'error'}
                                                onClick={() => removeRow(row.id)}><DeleteIcon/></Button>
                                    </ButtonGroup>
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
                <TableFooter>

                </TableFooter>
            </Table>

        </Grid2>
        <OfficeModal show={[showModal, setShowModal]} setOffices={setOffices}/>
        {popup ? <Popup title={popup?.title} message={popup?.message} open={true}/> : ''}
    </>)
}